import React, { useState } from 'react'
import styled from 'styled-components'
import { SubHeadingText } from '../../styles'

const FilterCon = styled.div`
  .container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    border-radius: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid #c4c4c4;
    border-radius: 0;
  }
  .container:hover input ~ .checkmark {
    background-color: white;
  }
  .container input:checked ~ .checkmark {
    background-color: var(--blue);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  .container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 0%;
    background: var(--blue);
  }
`

const ColourMob = ({ context, products }) => {
  const [colour, setColour] = useState(context.filteredColour)

  const handleFilterColour = target => {
    console.log(target);
    if (target.value === colour) {
      setColour('all')
      context.updateFilterColour('all')
      target.checked = false
    } else {
      setColour(target.value)
      context.updateFilterColour(target.value)
    }
  }

  const renderFilters = () => {
    return (
      <div>
        <label class="container">
          Black
          <input
            type="radio"
            name="colour"
            value="Black"
            onClick={event => handleFilterColour(event.target)}
          />
          <span class="checkmark"></span>
        </label>
        <label class="container">
          Yellow
          <input
            type="radio"
            name="colour"
            value="Yellow"
            onClick={event => handleFilterColour(event.target)}
          />
          <span class="checkmark"></span>
        </label>
        <label class="container">
          Magenta
          <input
            type="radio"
            name="colour"
            value="Magenta"
            onClick={event => handleFilterColour(event.target)}
          />
          <span class="checkmark"></span>
        </label>
        <label class="container">
          Cyan
          <input
            type="radio"
            name="colour"
            value="Cyan"
            onClick={event => handleFilterColour(event.target)}
          />
          <span class="checkmark"></span>
        </label>
        <label class="container">
          Tri-Colour
          <input
            type="radio"
            name="colour"
            value="Tri-Color"
            onClick={event => handleFilterColour(event.target)}
          />
          <span class="checkmark"></span>
        </label>
        <br></br>
      </div>
    )
  }

  return (
    <FilterCon>
      <SubHeadingText className="filterHead">Colour</SubHeadingText>
      {renderFilters()}
      <br />
    </FilterCon>
  )
}

export default ColourMob
