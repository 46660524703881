import React, { useState } from 'react'
import Collapse, { Panel } from 'rc-collapse'
import 'rc-collapse/assets/index.css'
import styled from 'styled-components'

const FilterCon = styled.div`
  .rc-collapse {
    background: transparent;
    border: none;
    margin-left: -20px;
    .rc-collapse-item {
      .rc-collapse-header {
        color: var(--black);
        font-size: 18px;
        line-height: 27px;
        font-family: 'neue-haas-grotesk-display-bold';
      }
      .rc-collapse-header:focus {
        outline: none;
      }
      .rc-collapse-content {
        color: var(--black);
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.05em;
        margin-left: 30px;
      }
    }
  }

  .container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    border-radius: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid #c4c4c4;
    border-radius: 0;
  }
  .container:hover input ~ .checkmark {
    background-color: white;
  }
  .container input:checked ~ .checkmark {
    background-color: var(--blue);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  .container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 0%;
    background: var(--blue);
  }
`

const Collection = ({ context, products }) => {
  const [type, setType] = useState(context.store.filteredType)

  const renderFilters = () => {
    return (
      <Panel header="Product Type" headerClass="my-header-class">
        <div>
          <label class="container">
            All
            <input
              type="radio"
              name="type"
              value="all"
              onClick={event => handleFilterType(event.target.value)}
            />
            <span class="checkmark"></span>
          </label>
          {type === 'Ink Cartridge' && (
            <label class="container">
              Ink Cartridge
              <input
                type="radio"
                name="type"
                checked
                value="Ink Cartridge"
                onClick={event => handleFilterType(event.target.value)}
              />
              <span class="checkmark"></span>
            </label>
          )}
          {type !== 'Ink Cartridge' && (
            <label class="container">
              Ink Cartridge
              <input
                type="radio"
                name="type"
                value="Ink Cartridge"
                onClick={event => handleFilterType(event.target.value)}
              />
              <span class="checkmark"></span>
            </label>
          )}
          <label class="container">
            Laser Toner Cartridge
            <input
              type="radio"
              name="type"
              value="Laser Toner Cartridge"
              onClick={event => handleFilterType(event.target.value)}
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </Panel>
    )
  }

  const handleFilterType = value => {
    setType(value)
    context.updateFilterType(value)
  }

  function expandIcon({ isActive }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="plus"
        width="15"
        height="15"
        viewBox="0 0 160 160"
        fill="currentColor"
        style={{
          margin: '0 10px 0 0',
          transition: 'transform .3s',
          transform: `rotate(${isActive ? 135 : 0}deg)`,
          transformOrigin: 'center',
        }}
      >
        <rect className="vertical-line" x="70" width="20" height="160" />
        <rect className="horizontal-line" y="70" width="160" height="20" />
      </svg>
    )
  }

  return (
    <FilterCon>
      <Collapse accordion={true} expandIcon={expandIcon}>
        {renderFilters()}
      </Collapse>
    </FilterCon>
  )
}

export default Collection
