import React, { useState } from 'react'
import styled from 'styled-components'
import { SubHeadingText } from '../../styles'

const FilterCon = styled.div`
  .container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    border-radius: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid #c4c4c4;
    border-radius: 0;
  }
  .container:hover input ~ .checkmark {
    background-color: white;
  }
  .container input:checked ~ .checkmark {
    background-color: var(--blue);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  .container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 0%;
    background: var(--blue);
  }
`

const CollectionMob = ({ context, products }) => {
  const [type, setType] = useState(context.store.filteredType)

  const handleFilterType = target => {
    console.log(target);
    if (target.value === type) {
      setType('all')
      context.updateFilterType('all')
      target.checked = false
    } else {
      setType(target.value)
      context.updateFilterType(target.value)
    }
  }

  const renderFilters = () => {
    return (
      <div>
        {type === 'Ink Cartridge' && (
          <label class="container">
            Ink Cartridge
            <input
              type="radio"
              name="type"
              checked
              value="Ink Cartridge"
              onClick={event => handleFilterType(event.target)}
            />
            <span class="checkmark"></span>
          </label>
        )}
        {type !== 'Ink Cartridge' && (
          <label class="container">
            Ink Cartridge
            <input
              type="radio"
              name="type"
              value="Ink Cartridge"
              onClick={event => handleFilterType(event.target)}
            />
            <span class="checkmark"></span>
          </label>
        )}
        <label class="container">
          Laser Toner Cartridge
          <input
            type="radio"
            name="type"
            value="Laser Toner Cartridge"
            onClick={event => handleFilterType(event.target)}
          />
          <span class="checkmark"></span>
        </label>
      </div>
    )
  }

  return (
    <FilterCon>
      <SubHeadingText className="filterHead">ProductType</SubHeadingText>
      {renderFilters()}
      <br />
    </FilterCon>
  )
}

export default CollectionMob
