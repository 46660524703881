import React from 'react'

import SEO from '~/components/seo'
import ProductGrid from '~/components/ProductGrid'
import Footer from '../components/Footer'

const ProductsPage = () => (
  <>
    <SEO title="Products" keywords={[`gatsby`, `application`, `react`]} />
    <ProductGrid />
    <Footer />
  </>
)

export default ProductsPage
