import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import media from '../../styles/media'
import StoreContext from '~/context/StoreContext'
import {
  Grid,
  Product,
  Title,
  PriceTag,
  ProductImage,
  BuyLink,
  HeadingText,
  Divider,
  SubHeadingText,
  ProductImgContainer,
  Button,
} from '../../styles'
import { isMobile } from 'react-device-detect'
import Navigation from '../Navigation'
import Collection from './collection'
import Colour from './colour'
import Price from './price'
import $ from 'jquery'
import PriceMob from './priceMob'
import ColourMob from './colourMob'
import CollectionMob from './collectionMob'

const ProductsCon = styled.div`
  margin: 0;
  display: block;
  .filter {
    margin: 0 var(--gutter-s);
  }
  .desk {
    display: none;
  }
  .showFilter {
    display: flex;
    p {
      margin-left: 1em;
      margin-top: 3.5%;
      color: var(--blue);
    }
  }
  ${media.mobileL`
  .showFilter {
    p {
      margin-top: 2%;
    }
  }
  `}
  ${media.laptop`
    margin: var(--gutter-m);
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    button {
      grid-column: 2;
    }
    .filter {
      margin: 0;
    }
    .desk {
      display: block;
    }
    .showFilter {
      display: none;
    }
  `}
`

const StyledMenu = styled.div`
  flex-direction: column;
  justify-content: center;
  background: white;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  height: 100vh;
  width: 100vw;
  text-align: left;
  padding: var(--gutter-s);
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  transition: transform 0.5s ease-in-out;
  transition-delay: ${({ open }) => (open ? '0' : '0.5s')};
  margin-bottom: var(--gutter-button);
  z-index: 30;
  .close {
    text-align: right;
    font-size: 25px;
  }
  .filterButtons {
    display: flex;
    margin-right: var(--gutter-button);
    justify-content: space-between;
    height: 100px;
  }
`

const ProductGrid = () => {
  const [open, setOpen] = useState(false)
  const [shown, setShown] = useState(9)
  const [clicked, setClicked] = useState(1)

  const context = useContext(StoreContext)

  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
          edges {
            node {
              id
              title
              handle
              createdAt
              productType
              tags
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                sku
                price
              }
            }
          }
        }
      }
    `
  )

  const closeFilter = () => {
    context.updateFilterType('all')
    context.updateFilterPrice('all')
    context.updateFilterColour('all')
    setOpen(!open)
  }

  const showMore = () => {
    setShown(shown + 9)
    setClicked(clicked + 1)
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $(`#productList a:nth-child(${clicked * 9})`).offset().top,
      },
      1000
    )
  }

  return (
    <>
      <Navigation />
      {!isMobile && (
        <HeadingText className="black spaced smaller">
          Toners &amp; Ink
        </HeadingText>
      )}
      {isMobile && (
        <HeadingText className="black smallSpaced">
          Toners &amp; Ink
        </HeadingText>
      )}
      <Divider />
      <ProductsCon>
        <div className="filter">
          <div className="desk">
            <Price context={context} products={allShopifyProduct.edges} />
            <Collection
              context={context}
              products={allShopifyProduct.edges}
            />
            <Colour context={context} products={allShopifyProduct.edges} />
          </div>
          <div className="showFilter">
            <SubHeadingText onClick={() => setOpen(!open)}>
              Filters
            </SubHeadingText>
            <p className="blue" style={{ fontSize: '16px' }}>
              &#x25B8;
            </p>
            <StyledMenu open={open}>
              <PriceMob
                context={context}
                products={allShopifyProduct.edges}
              />
              <CollectionMob
                context={context}
                products={allShopifyProduct.edges}
              />
              <ColourMob
                context={context}
                products={allShopifyProduct.edges}
              />
              <div className="filterButtons">
                <Button
                  className="clear bold blue filter"
                  onClick={() => closeFilter()}
                >
                  Cancel
                </Button>
                <Button
                  className="bold filter"
                  onClick={() => setOpen(!open)}
                >
                  Apply
                </Button>
              </div>
            </StyledMenu>
          </div>
        </div>
        {isMobile && (
          <>
            {' '}
            <Divider />
            <br /> <br />{' '}
          </>
        )}
        <Grid id="productList">
          {allShopifyProduct.edges ? (
            allShopifyProduct.edges
              .filter(p =>
                context.store.filteredType === 'all'
                  ? p
                  : p.node.productType.includes(context.store.filteredType)
              )
              .filter(p =>
                context.store.filteredColour === 'all'
                  ? p
                  : p.node.tags.includes(context.store.filteredColour)
              )
              .filter(p =>
                context.store.filteredPrice === 'all'
                  ? p
                  : p.node.tags.includes(context.store.filteredPrice)
              )
              .slice(0, shown)
              .map(
                ({
                  node: {
                    id,
                    handle,
                    title,
                    images: [firstImage],
                    variants: [firstVariant],
                  },
                }) => (
                  <a
                    href={`/product/${handle}/`}
                    key={id}
                    className="productItem"
                    id={`product product-${id}`}
                  >
                    <Product>
                      <ProductImgContainer>
                        {firstImage && firstImage.localFile && (
                          <ProductImage
                            fluid={firstImage.localFile.childImageSharp.fluid}
                            alt={handle}
                          />
                        )}
                      </ProductImgContainer>
                      <Title>{title}</Title>
                      <PriceTag>
                        R
                        {firstVariant.price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                      </PriceTag>
                      <BuyLink to={`/product/${handle}/`}>Add to Cart</BuyLink>
                    </Product>
                  </a>
                )
              )
          ) : (
            <p>No Products found!</p>
          )}
        </Grid>
        <br />
        {isMobile && shown < allShopifyProduct.edges.length && (
          <Button
            onClick={showMore}
            className="spaced more-button"
            id="loadMore"
          >
            Show More
          </Button>
        )}
        {!isMobile && shown < allShopifyProduct.edges.length && (
          <Button onClick={showMore} className="spaced" id="loadMore">
            Show More
          </Button>
        )}
      </ProductsCon>
    </>
  )
}

export default ProductGrid
