import React, { useState } from 'react'
import styled from 'styled-components'
import { SubHeadingText } from '../../styles'

const FilterCon = styled.div`
  .container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    border-radius: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid #c4c4c4;
    border-radius: 0;
  }
  .container:hover input ~ .checkmark {
    background-color: white;
  }
  .container input:checked ~ .checkmark {
    background-color: var(--blue);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  .container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 0%;
    background: var(--blue);
  }
`

const PriceMob = ({ context, products }) => {
  const [price, setPrice] = useState(context.filteredPrice)

  const handleFilterPrice = target => {
    if (target.value === price) {
      setPrice('all')
      context.updateFilterPrice('all')
      target.checked = false
    } else {
      setPrice(target.value)
      context.updateFilterPrice(target.value)
    }
  }

  const renderFilters = () => {
    return (
      <div>
        <label class="container">
          R400 - R500
          <input
            type="radio"
            name="price"
            value="R400 - R500"
            onClick={event => handleFilterPrice(event.target)}
          />
          <span class="checkmark"></span>
        </label>
        <label class="container">
          R500 - R1500
          <input
            type="radio"
            name="price"
            value="R500 - R1500"
            onClick={event => handleFilterPrice(event.target)}
          />
          <span class="checkmark"></span>
        </label>
        <label class="container">
          R1500 - R2000
          <input
            type="radio"
            name="price"
            value="R1500 - R2000"
            onClick={event => handleFilterPrice(event.target)}
          />
          <span class="checkmark"></span>
        </label>
        <label class="container">
          Above R2000
          <input
            type="radio"
            name="price"
            value="Above R2000"
            onClick={event => handleFilterPrice(event.target)}
          />
          <span class="checkmark"></span>
        </label>
      </div>
    )
  }

  return (
    <FilterCon>
      <SubHeadingText className="filterHead">Price</SubHeadingText>
      {renderFilters()}
      <br />
    </FilterCon>
  )
}

export default PriceMob
